import React from 'react';
import { dayNames, getFormattedTime } from 'Helpers/dates';
import { isEmpty } from 'Helpers/objects';
import { NAME_TO_LOCATION_ID, SCREENS, TRACKING_PAGES, FLOW_TYPES, INSPECTION_TYPES } from './constants';
import { FormattedDate } from 'react-intl';
import { UserSession } from 'olx-autos-landing-page';
import { getCityLocationId } from 'Helpers/locations';
import SITE_CODES from 'Constants/siteCodes';
import {
    CONFIRMATION_URL
} from 'Autos/Inspection/constants';

export const getLocParams = selectedLocation => {
    const locationId = getCityLocationId(selectedLocation);

    const params = {
        locationId
    };

    const { latitude, longitude, inspectionType } = selectedLocation;

    if (latitude) {
        params.lat = latitude;
    }

    if (longitude) {
        params.lng = longitude;
    }

    params.type = 'INSPECTION';

    if (inspectionType) {
        params.type = inspectionType;
    }

    return params;
};

export const getInspectionTypeCentres = (centreList, inspectionType) => {
    if (!isEmpty(centreList)) {
        const inspectionTypeCentres = centreList.filter(centre =>
            (centre.type === FLOW_TYPES.INSPECTION_HOME && inspectionType === INSPECTION_TYPES.HOME)
            || (centre.type === FLOW_TYPES.INSPECTION_ONLY && inspectionType === INSPECTION_TYPES.STORE)
        );

        return inspectionTypeCentres?.[0];
    }
    return null;
};

export const getdefaultCentre = (centreList, inspectionType) => {
    const inspectionTypeCentres = getInspectionTypeCentres(centreList, inspectionType);

    return inspectionTypeCentres?.centres?.[0] || null;
};

export const getCenterId = (centreList, inspectionType, selectedCentre, shouldSendAllCenterId, isSendSelectedCentre) => {
    // TO HANDLE SELECTED CENTRES OF STORE INSPECTION
    if (isSendSelectedCentre) {
        return selectedCentre.id;
    }

    if (shouldSendAllCenterId && !isEmpty(centreList)) {
        const inspectionTypeInFlowType = inspectionType === INSPECTION_TYPES.HOME ? FLOW_TYPES.INSPECTION_HOME : FLOW_TYPES.INSPECTION_ONLY;
        const centresArray = centreList.find(centre => centre.type === inspectionTypeInFlowType)?.centres;

        if (isEmpty(centresArray)) {
            return selectedCentre.id;
        }
        return centresArray.reduce((acc, curr) => [...acc, curr.id], []).join(',');
    }
    return selectedCentre.id;
};

export const getSelectedCenter = (centerId, centreList, inspectionType) => {
    const inspectionTypeInFlowType = inspectionType === INSPECTION_TYPES.HOME ? FLOW_TYPES.INSPECTION_HOME : FLOW_TYPES.INSPECTION_ONLY;

    if (!isEmpty(centreList)) {
        const centresArray = centreList.filter(centre => centre.type === inspectionTypeInFlowType);

        if (!isEmpty(centresArray) && !isEmpty(centresArray[0].centres)) {
            return centresArray[0].centres.find(c => c.id === centerId);
        }

        return null;
    }

    return null;
};

export const getLocParamsV2 = (selectedLocation, isPPCFlow, nonServiceableCenterSuggestionExpVariant) => {
    const params = {};

    const { latitude, lat, longitude, lng, citycode } = selectedLocation;

    if (latitude || lat) {
        params.lat = latitude || lat;
    }

    if (longitude || lng) {
        params.lng = longitude || lng;
    }

    if (citycode) {
        params.citycode = citycode;
    }

    if (nonServiceableCenterSuggestionExpVariant) {
        params.showNonServiceableCentreSuggestion = nonServiceableCenterSuggestionExpVariant;
    }

    params.isPPCFlow = !!isPPCFlow;

    return params;
};

export const checkForLocation = selectedLocation => {
    const { latitude, longitude, gps } = selectedLocation;

    if (!gps) {
        return true;
    }

    if (gps && latitude && longitude) {
        return true;
    }
    return false;
};

export const getIsCity = location => {
    if (location.serviceType) {
        return true;
    }

    return location.type === 'CITY'
        || (location.addressComponents && location.addressComponents.some(loc => loc.type === 'CITY'))
        || location.isCmcCity;
};

export const getBooking = localStorage => {
    return localStorage.getItem('booking');
};

export const getDayDate = slotDetails => {
    const { date } = slotDetails;
    const dateArr = date.split('-');
    const dateObj = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
    // eslint-disable-next-line no-magic-numbers
    const day = dayNames[dateObj.getDay()].slice(0, 3);

    return {
        day,
        date: <FormattedDate value={ date } weekday="short" month="short" year="numeric" day="2-digit" />
    };
};

export const getDateWithTimeFromSlotDetails = slotDetails => {
    const { date, slot } = slotDetails;

    const timeArr = slot.split(':').map(str => Number(str));
    const dateArr = date.split('-').map(str => Number(str));

    return new Date(dateArr[0], dateArr[1] - 1, dateArr[2], timeArr[0], timeArr[1], timeArr[2]);
};

export const getFormattedTimeSlot = (slotDetails, siteCode, isglobalTimeRequired = false) => {
    const date = getDateWithTimeFromSlotDetails(slotDetails);
    const time = slotDetails.displayValue.split('-')
        .map(range =>
            getFormattedTime(range.trim(), false, isglobalTimeRequired)
        ).join(' - ');

    /**
     * Show full day for indonesia
     */
    const isIndonesia = [SITE_CODES.OLX_ID].includes(siteCode);

    return {
        date: <FormattedDate value={ date } weekday={ isIndonesia ? 'long' : 'short' } month="short" year="numeric" day="2-digit" />,
        time
    };
};

export const getDaysLeft = slotDetails => {
    const { date, slot } = slotDetails;
    const timeArr = slot.split(':');
    // eslint-disable-next-line no-magic-numbers
    const oneDay = 24 * 60 * 60 * 1000;
    const dateArr = date.split('-');
    const firstDate = new Date(dateArr[0], dateArr[1] - 1, dateArr[2], timeArr[0], timeArr[1], timeArr[2]);
    const secondDate = Date.now();

    const diffDays = Math.abs((firstDate - secondDate) / oneDay);

    return diffDays >= 1 ? Math.floor(diffDays) : 0;
};

export const validateDataFromLS = (localStorage, siteCode) => {
    if (isEmpty(localStorage)) {
        return null;
    }
    const countriesCheck = [SITE_CODES.OLX_TR].includes(siteCode);
    const valuationUserValues = localStorage.getItem('valuationUserValues');
    const valuationPredictedPrice = localStorage.getItem('valuationPredictedPrice');
    const valuationDataValues = localStorage.getItem('inspectionFieldsMap');
    let isMileageOrTransmissionReq = true;

    if ((!valuationUserValues || !valuationPredictedPrice)) {
        return null;
    }

    const parsedValues = JSON.parse(valuationUserValues);

    try {
        const parsedDataValues = JSON.parse(valuationDataValues);

        if (parsedDataValues.mileage || parsedDataValues.transmission) {
            if (parsedValues.mileage || parsedValues.transmission) {
                isMileageOrTransmissionReq = true;
            }
            else {
                isMileageOrTransmissionReq = false;
            }
        }
    }
    catch (error) {
        isMileageOrTransmissionReq = true;
    }

    // Variant is optional for TR
    const isVariant = parsedValues.variant || countriesCheck;

    // mileage and transmission not present in TR
    isMileageOrTransmissionReq = isMileageOrTransmissionReq || countriesCheck;

    if (!(parsedValues.phone && parsedValues.year && isVariant && parsedValues.make
        && parsedValues.model && isMileageOrTransmissionReq)) {
        return null;
    }

    return {
        phoneKey: parsedValues.phone,
        phone: parsedValues['phone-displayValue'],
        yearKey: parsedValues.year,
        year: parsedValues['year-displayValue'],
        variantKey: parsedValues.variant,
        variant: parsedValues['variant-displayValue'],
        makeKey: parsedValues.make,
        make: parsedValues['make-displayValue'],
        modelKey: parsedValues.model,
        model: parsedValues['model-displayValue'],
        mileageKey: parsedValues.mileage,
        mileage: parsedValues['mileage-displayValue'],
        firstOwnerKey: parsedValues['first_owner'],
        firstOwner: parsedValues['first_owner-displayValue'],
        email: parsedValues.email,
        emailKey: parsedValues['email-displayValue'],
        registeredStateKey: parsedValues.registeredState,
        registeredState: parsedValues['registeredState-displayValue'],
        transmissionKey: parsedValues.transmission,
        transmission: parsedValues['transmission-displayValue'],
        nameKey: parsedValues.name,
        name: parsedValues['name-displayValue'],
        faceLiftKey: parsedValues.faceLift,
        faceLift: parsedValues['faceLift-displayValue'],
        bodyTypeKey: parsedValues.bodyType,
        bodyType: parsedValues['bodyType-displayValue'],
        wheelDriveKey: parsedValues.wheelDrive,
        wheelDrive: parsedValues['wheelDrive-displayValue'],
        engineKey: parsedValues.engine,
        engine: parsedValues['engine-displayValue'],
        seatKey: parsedValues.seat,
        seat: parsedValues['seat-displayValue'],
        sellingUrgencyKey: parsedValues.sellingUrgency,
        sellingUrgency: parsedValues['sellingUrgency-displayValue']
    };
};

export const getSelectFrom = ({ pathname }, showLocationPopup = false, selectedTab) => {
    if (pathname.includes(SCREENS.CHANGE_CENTER)) {
        return TRACKING_PAGES.BOOKING_CENTER_LIST;
    }

    if (pathname.includes(SCREENS.CHANGE_LOCATION) || pathname.includes(SCREENS.SELECT_MAP_LOCATION) || showLocationPopup) {
        return TRACKING_PAGES.BOOKING_LOCATION;
    }

    if (pathname.includes(SCREENS.CONFIRMED_APPOINTMENT) || pathname.includes(CONFIRMATION_URL)) {
        return TRACKING_PAGES.BOOKING_CONFIRMATION;
    }

    if (pathname.includes(SCREENS.NON_CMC_CITY)) {
        return TRACKING_PAGES.BOOKING_CITY_NOT_SERVICEABLE;
    }

    if (pathname.includes(SCREENS.CONFIRM_PHONE)) {
        return TRACKING_PAGES.BOOKING_REVIEW_USER_DETAILS;
    }

    return selectedTab === 1 ? TRACKING_PAGES.BOOKIN_HOME_ADD_SLOT_SEL : TRACKING_PAGES.BOOKING_SLOT_SELECTION;
};

export const getSelectedDateTime = (booking, slots) => {
    let selectedDateId = 1;
    let selectedTimeId = 0;

    if (isEmpty(slots)) {
        return {};
    }

    if (isEmpty(booking)) {
        const MAX_AVAILABLE_DATES_TO_CHECK = 3;

        const isSlotAvailable = ele => {
            if (ele.timeslots.length > 0) {
                return ele.timeslots.some(item =>
                    item.available);
            }
            return false;
        };

        const availableDateSlots = slots.reduce((acc, current, index) => {
            if (isSlotAvailable(current)) {
                acc.push(index);
            }
            return acc;
        }, []);

        const availableDateSlotsToRandomize = [];

        for (let i = 0; availableDateSlots[i] <= (MAX_AVAILABLE_DATES_TO_CHECK - 1); i++) {
            availableDateSlotsToRandomize.push(availableDateSlots[i]);
        }

        selectedDateId = availableDateSlotsToRandomize.length ? availableDateSlotsToRandomize[Math.floor((Math.random() * availableDateSlotsToRandomize.length))] : availableDateSlots[0];

        const availableTimeSlots = slots[selectedDateId].timeslots.reduce((acc, current, index) => {
            if (current.available) {
                acc.push(index);
            }
            return acc;
        }, []);

        selectedTimeId = availableTimeSlots[Math.floor((Math.random() * availableTimeSlots.length))];
    }
    else {
        slots.forEach(({ date, timeslots }, idx) => {
            if (date === booking.slotDetails.date) {
                selectedDateId = idx;
                timeslots.forEach(({ time }, timeIdx) => {
                    if (time === booking.slotDetails.slot) {
                        selectedTimeId = timeIdx;
                    }
                });
            }
        });
    }
    // eslint-disable-next-line consistent-return
    return {
        selectedDateId,
        selectedTimeId
    };
};

export const getIsSelected = (selectedLocation, location) => {
    if (selectedLocation.isCmcCity) {
        return location.id == selectedLocation.id;
    }

    const isCity = getIsCity(selectedLocation);

    if (isCity) {
        const { locationId } = getLocParams(selectedLocation);

        return locationId == location.id;
    }

    return false;
};

export const formatSlotDetailsForTracking = (slotDetails = {}) => `${slotDetails.date} ${decodeURIComponent(slotDetails.slot)}`;

export const getPredictedPriceRange = () => {
    try {
        const predictedPrice = UserSession.getEvaluationPredictedPrice() || {};

        if (predictedPrice) {
            const { predictions: { list: [{ price: { max = '', min = '' } = {}}] } = {}} = predictedPrice;

            return min === max ? min : `${min} - ${max}`;
        }
        return '';
    }
    catch (error) {
        return '';
    }
};

export const getLocationValueByName = (campaignValue, INSPECTION_CITIES_NAME_TO_ID_MAP = NAME_TO_LOCATION_ID) => {
    try {
        const locName = campaignValue.split('|')[1];

        const locList = INSPECTION_CITIES_NAME_TO_ID_MAP.filter(loc => loc.code === locName);

        if (locList[0]) {
            if (Array.isArray(locList[0].id)) {
                return locList[0].id[0];
            }

            return locList[0].id;
        }

        return '';
    }
    catch (error) {
        return '';
    }
};

export const getLoginKeyValue = (valuationDetails, config) => {
    const isEmailAuthenticity = [
        SITE_CODES.OLX_CO,
        SITE_CODES.OLX_PE,
        SITE_CODES.OLX_EC
    ].includes(config.get('siteCode'));
    const output = {
        loginValue: '',
        loginMethod: ''
    };

    if (isEmailAuthenticity) {
        if (valuationDetails?.email) {
            output.loginValue = valuationDetails.email;
            output.loginMethod = 'email';
        }
    }
    else if (valuationDetails?.phone) {
        output.loginValue = valuationDetails.phone;
        output.loginMethod = 'phone';
    }
    return output;
};

export function encodeAndLowerCase(value) {
    return encodeURI(value).toLocaleLowerCase();
}

